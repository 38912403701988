// @flow

import logo from "./images/splash.png";
import "./App.css";

import React from "react";

function App() {
  return (
    <div className=" bg-slate-800 h-[100vh] font-mono flex-col">
      <div className=" flex justify-center pt-[20%]">
        <img src={logo} className="w-[15rem]" alt="logo" />
      </div>
      <div className="text-[2rem] md:text-[3rem] flex justify-center">
        <div role="img" aria-label="construction">
          🚧
        </div>
        <p className=" text-white font-bold px-4 text-[1.3rem] md:text-[2rem]">
          Work In Progress
        </p>
        <div role="img" aria-label="construction">
          🚧
        </div>
      </div>
    </div>
  );
}

export default App;
